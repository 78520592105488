.comment-btn{
    position: absolute;
    top: 40%;
    right: 0;
    padding: 15px 5px ;
    background-color: rgba(35, 55, 75, 0.9);
    cursor: pointer;
}
.comment-btn p{
    margin: 0;
    color: #fff;
}
.input-wrapper,
.input-wrapper-active{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: #fff;
    transition: 0.3s all ease;
}
.input-wrapper{
    transform: translateX(-100vw);
}
.input-container,
.input-container-active{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    background-color: rgba(35, 55, 75, 0.9);
    transition: 0.5s all ease-in-out;
}
.input-container{
    opacity: 0;
    transform: scale(0.5);
    transition: 0.5s all ease-in-out;
}
.btn-close{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: transparent;
    background-color: transparent;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    cursor: pointer;
    transition: 0.3s all ease;
}
.comment-aria,
.comment-username{
    width: 100%;
    resize: none;
    padding: 10px;
    margin: 10px 0;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.btn-send{
    border: transparent;
    background-color: transparent;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    transition: 0.3s all ease;
}
.btn-send:hover,
.btn-close:hover{
    color: #000;
}
.gif-success{
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15%;
    border-radius: 20px;
    box-shadow: 0px 0px 11px 6px #00000060
}