.map-container {
    height: 100vh;
}

.sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    margin: 12px;
    border-radius: 4px;
}
.mapboxgl-popup-content{
    box-shadow: 0px 0px 11px 6px #00000060;
}